<!--
 完成实名认证
-->

<template>
  <div>
    <div class="page-bg"></div>
    <div class="auth-box">
      <div class="auth-title">完成实名认证</div>
      <div class="over" v-if="showInvite && activityId == 1">
        完成认证后，有机会获得数字藏品空投一份
      </div>
      <div class="auth-form">
        <van-form @submit="onSubmit">
          <van-field
            v-model="mobile"
            type="tel"
            class="field"
            name="mobile"
            label="手机号"
            disabled
            :border="false"
            placeholder="正在自动录入手机号"
          />

          <van-field
            v-model="id_name"
            name="id_name"
            class="field"
            label="真实姓名"
            placeholder="请输入真实姓名"
            :border="false"
            @blur="checkBlur"
          />

          <van-field
            v-model="id_number"
            name="id_number"
            class="field"
            label="身份证号"
            placeholder="请输入身份证号"
            :border="false"
            @blur="checkBlur"
          />

          <van-button
            block
            plain
            native-type="submit"
            type="default"
            class="submit-btn"
            :disabled="submitDisabled"
            >提交</van-button
          >
        </van-form>
      </div>
    </div>

    <van-dialog
      v-model:show="showSmall"
      message="手机号机主姓名、身份证姓名和真实姓名不一致。"
      confirm-button-text="知道了"
    >
      <div class="auth-dialog">
        <div class="auth-dialog-title">实名认证失败</div>
        <div class="auth-dialog-desc">{{ let_message }}</div>
        <div class="auth-dialog-hint">今日还剩余{{ let_cnt }}次验证机会</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue';
import { authMetaSimple, getUserData } from '@/service/user';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { Toast, Dialog } from 'vant';
import { nftUtils } from '@/utils';
export default {
  setup() {
    const router = useRouter();

    const route = useRoute();
    const store = useStore();
    const state = reactive({
      oldMobile: '',
      mobile: '',
      id_name: '',
      id_number: '',
      submitDisabled: true,
      showSmall: false,
      let_cnt: 3,
      let_message: '手机号机主姓名、身份证姓名和真实姓名不一致。',
      showInvite: false,
      canSubmit: true, // 提交
      activityId: route.query.activityId || null,
      isApp: nftUtils.isInApp()
    });

    onMounted(async () => {
      route.query.invite_user_id && (state.showInvite = true);
      if (store.state.loginInfo && store.state.loginInfo.mobile) {
        state.oldMobile = store.state.loginInfo.mobile;
        state.mobile = nftUtils.getNoFullMobile(state.oldMobile);
      }
      const { data, status } = await getUserData();
      if (data && data.mobile && status === 0) {
        state.oldMobile = data.mobile;
        state.mobile = nftUtils.getNoFullMobile(state.oldMobile);
      }
    });

    const checkBlur = () => {
      state.submitDisabled = !state.id_name || !state.id_number;
    };

    const onSubmit = async value => {
      if (state.showInvite) {
        nftUtils.setcnzzRecordByBtn('通过邀请码提交实名认证');
      } else {
        nftUtils.setcnzzRecordByBtn('提交实名认证');
      }
      const age = nftUtils.getIdNumberAge(state.id_number);
      if (age < 14) {
        Dialog.alert({
          message: '应政策要求，未满14岁不可购买数字商品哦~',
          confirmButtonText: '知道了'
        }).then(() => {
          // on close
        });
        return;
      }
      value.mobile = state.oldMobile;
      if (state.canSubmit) {
        state.canSubmit = false;
        const { status, message, data } = await authMetaSimple(value);
        state.canSubmit = true;
        if (status === 0) {
          // if (state.isApp) {
          // 刷新本地token
          Toast('实名认证通过！');
          if (window.history.length <= 2) {
            if (nftUtils.isInWx()) {
              window.location.href = window.location.origin + '/home';
            } else {
              // router.push({ path: state.showInvite ? '/home' : '/user', replace: true });
              router.push({ path: '/home', replace: true });
            }
            // router.push({ path: '/user', replace: true });
          } else {
            router.go(-1);
          }
          // } else {
          //   router.push({
          //     path: '/downloadApp',
          //     query: { activityId: state.activityId }
          //   });
          // }
        } else if (status === 5 && data) {
          if (message) {
            state.let_message = message;
          }
          state.let_cnt = data.let_cnt;
          state.showSmall = true;
          return;
        } else if (message) {
          Toast(message);
        }
      }
    };

    return {
      ...toRefs(state),
      checkBlur,
      onSubmit
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.over {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  margin-left: 5%;
  margin-top: 5px;
}
.auth-box {
  font-size: 20px;
  padding-top: 40px;
  position: relative;
  width: 100%;
  .auth-title {
    margin-top: 20px;
    margin-left: 5%;
  }
  .auth-form {
    margin-top: 40px;
  }
  .field {
    margin-top: 20px;
    width: 335px;
    height: 44px;
    background-color: $primaryc;
    left: 50%;
    transform: translateX(-50%);
    --van-field-label-color: #fff;
    --van-field-input-text-color: #fff;
    --van-field-placeholder-text-color: #6d6979;
    --van-field-input-disabled-text-color: #fff;
    --van-field-disabled-text-color: #fff;
  }
}

.auth-dialog {
  margin: 25px;
  display: flex;

  align-items: center;
  flex-direction: column;
  &-title {
    font-size: 18px;
  }
  &-desc {
    font-size: 16px;
    margin-top: 24px;
    text-align: center;
  }
  &-hint {
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 5px;
    color: $redc;
  }
}
</style>
